<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 g" v-if="payments.length">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-list"></i>
            {{ e("old-requests") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>رقم الطلب</th>
                <th>التاريح</th>
                <th>المبلغ</th>
                <th>تفاصيل</th>
                <th>الأجهزة</th>
                <th>الحالة</th>
              </thead>
              <tbody>
                <tr v-for="pay in payments" :key="pay._id">
                  <td>
                    {{ pay.order_id }}
                  </td>
                  <td>
                    {{ pay.date }}
                  </td>
                  <td>
                    {{ pay.amount }}
                  </td>
                  <td>
                    {{ pay.notes }}
                    <span v-if="pay.file">
                      <br />
                      <a :href="pay.file" v-if="pay.file" target="_blank"
                        ><i class="fa fa-file"></i> File</a
                      ></span
                    >
                  </td>
                  <td>
                    {{ pay.devices.join() }}
                  </td>
                  <td>
                    <span v-if="pay.status == '1'" class="badge bg-success">
                      {{ e("accepted") }}
                    </span>
                    <span v-if="pay.status == '2'" class="badge bg-danger">
                      {{ e("rejected") }}
                    </span>
                    <span v-if="pay.status == '0'" class="badge bg-warning">
                      {{ e("waiting") }}
                    </span>
                    <span class="g" v-if="pay.status == '2'">
                      {{ e("reason") }}: {{ pay.admin_notes }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-usd"></i>
            {{ e("pay-or-renew") }}
          </h4>
        </div>
        <div class="card-body">
          <div v-html="content"></div>
        </div>
        <div class="card-footer">
          <div class="col-12 col-lg-7 c">
            <div class="col-12">
              <h5 class="text-danger">
                {{ e("select-devices-you-pay") }}
              </h5>
            </div>
            <div
              class="col-12 border g"
              v-for="device in devices"
              :key="device._id"
            >
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                v-model="selected_devices"
                :value="device.serial_number"
                inline
              >
                <h5>{{ device.title }} ({{ device.serial_number }})</h5>
              </b-form-checkbox>
            </div>
            <hr />
            <div v-if="selected_devices.includes('app')">
              <div class="form-group">
                <h5 for="">{{ e("how-many-users-you-need") }}</h5>
                <input type="number" class="form-control" v-model="limit" />
                <small id="helpId" class="form-text text-muted">{{
                  e("you-will-pay-20-per-user")
                }}</small>
              </div>
              <h2>{{ e("total") }}: {{ limit * 20 }} ريال</h2>
              <hr />
            </div>
            <div class="form-group">
              <h5 for="">{{ e("amount") }}</h5>
              <input
                type="text"
                class="form-control form-control-lg"
                v-model="r.amount"
                :placeholder="e('write-here')"
              />
            </div>
            <div class="form-group">
              <h5 for="">{{ e("file-pay") }} ({{ e("optional") }})</h5>
              <input
                type="file"
                id="file"
                @change="uploadFile()"
                accept=".jpeg,.jpg,.png,.pdf,.gif"
                class="form-control form-control-lg"
              />
            </div>
            <div
              v-if="file.name"
              class="g border alert alert-success"
              style="margin-bottom: 12px !important"
            >
              <h5><i class="fa fa-check"></i> {{ e("file-uploaded") }}</h5>
              <strong>{{ file.name }}</strong>
            </div>
            <div class="form-group">
              <h5 for="">{{ e("pay-details") }}</h5>
              <textarea
                class="form-control"
                v-model="r.notes"
                :placeholder="e('write-here')"
              ></textarea>
            </div>
            <div class="col-12 text-center g">
              <button class="btn btn-relief-success" @click="send()">
                {{ e("send") }} <i class="fa fa-arrow-left"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BFormCheckbox, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
    BOverlay,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      content: "",
      devices: [],
      selected_devices: [],
      loading: true,
      r: {},
      file: {},
      payments: [],
      limit: null,
    };
  },
  created() {
    var g = this,
      _g = this;
    $.post(api + "/options")
      .then(function (r) {
        r = JSON.parse(r);
        g.content = r["payments"];
        $.post(api + "/devices/index", {
          jwt: g.user.jwt,
        })
          .then(function (response) {
            response = JSON.parse(response);
            if (response.status == 100) {
              var app = {
                title: g.e("application"),
                serial_number: "app",
              };
              _g.devices = { app, ...response.response };
              $.post(api + "/general/payments/list", {
                jwt: g.user.jwt,
              })
                .then(function (response) {
                  response = JSON.parse(response);
                  _g.payments = response;
                  g.loading = false;
                })
                .catch(function () {
                  _g.$toast({
                    component: ToastificationContent,
                    props: {
                      title: e("error"),
                      icon: "TimesIcon",
                      variant: "danger",
                    },
                  });
                  _g.loading = false;
                });
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: e("error"),
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: e("error"),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
            _g.loading = false;
          });
      })
      .catch(function (r) {
        alert(r);
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
    send() {
      var g = this,
        _g = this;
      if (g.selected_devices.length == 0 || !g.r.amount || !g.r.notes) {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: e("required-inputs"),
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      } else {
        $.post(api + "/options")
          .then(function (r) {
            r = JSON.parse(r);
            g.content = r["payments"];
            $.post(api + "/general/payments/add", {
              jwt: g.user.jwt,
              amount: g.r.amount,
              devices: g.selected_devices,
              file: g.file.path,
              notes: g.r.notes,
              limit: g.limit,
            })
              .then(function (response) {
                if (response == "ok") {
                  _g.$toast({
                    component: ToastificationContent,
                    props: {
                      title: e("done"),
                      icon: "CheckIcon",
                      variant: "success",
                    },
                  });
                  setTimeout(() => {
                    location.reload();
                  }, 1000);
                } else {
                  _g.$toast({
                    component: ToastificationContent,
                    props: {
                      title: e("error"),
                      icon: "TimesIcon",
                      variant: "danger",
                    },
                  });
                }
                _g.loading = false;
              })
              .catch(function () {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: e("error"),
                    icon: "TimesIcon",
                    variant: "danger",
                  },
                });
                _g.loading = false;
              });
          })
          .catch(function (r) {
            alert(r);
          });
      }
    },
    uploadFile() {
      this.loading = true;
      var formData = new FormData(),
        g = this;
      formData.append("file", $("#file")[0].files[0]);
      formData.append("jwt", this.user.jwt);
      $.ajax({
        url: api + "/general/upload",
        type: "POST",
        data: formData,
        processData: false,
        contentType: false,
        success: function (data) {
          g.loading = false;
          if (data.status) {
            g.file = data.data;
          } else {
            alert(e("error") + ": " + data.message);
          }
        },
      });
    },
  },
};
</script>